import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { Pages } from "cms";
import { TestimonalType } from 'components/Testimonal';
import { HomePageProps as HomePage } from "pages/HomePage";
import { JobPageProps as JobPage } from 'pages/JobPage';
import { PageProps as Page } from "pages/Page"
import { TeamPageProps } from 'pages/TeamPage';

type Arguments = {
  slug: string,
  preview: string,
};

export const fetchHomePage = createAsyncThunk<HomePage, string>(
  "pages/fetchHomePageStatus",
  async (preview: string) => {
    return await Pages.fetchHomePage(preview);
  }
);

export const fetchPage = createAsyncThunk<Page, Arguments>(
  "pages/fetchPageStatus",
  async ({slug, preview}) => {
    return await Pages.fetchPage(slug, preview);
  }
);

export const fetchJobPage = createAsyncThunk<JobPage, Arguments>(
  "pages/fetchJobPageStatus",
  async ({slug, preview}) => {
    return await Pages.fetchJobPage("careers/" + slug, preview);
  }
);

export const fetchTeamPage = createAsyncThunk<TeamPageProps, string>(
  "pages/fetchTeamPageStatus",
  async (preview: string) => {
    return await Pages.fetchTeamPage(preview);
  }
);

export const fetchTestimonialsPage = createAsyncThunk<TestimonalType[]>(
  "fetchTestimonialsPageStatus",
  async thunkAPI => {
    return await Pages.fetchTestimonialPage();
  }
);

type PagesState = {
  homePage: HomePage | null;
  pages: { [slug: string]: Page };
  teamPage: TeamPageProps | null;
  jobPages: { [slug: string]: JobPage };
  testimonialsPage: TestimonalType[];
};

const initialState: PagesState = {
  homePage: null,
  teamPage: null,
  pages: {},
  jobPages: {},
  testimonialsPage: [],
};

const pagesSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchHomePage.fulfilled, (state, { payload }) => {
      state.homePage = payload;
    });
    builder.addCase(fetchTeamPage.fulfilled, (state, { payload }) => {
      state.teamPage = payload;
    });
    builder.addCase(fetchPage.fulfilled, (state, { meta, payload }) => {
      state.pages[meta.arg.slug] = payload;
    });
    builder.addCase(fetchJobPage.fulfilled, (state, { meta, payload }) => {
      state.jobPages[meta.arg.slug] = payload;
    });
    builder.addCase(fetchTestimonialsPage.fulfilled, (state, { payload }) => {
      state.testimonialsPage = payload;
    });
  },
});

export default pagesSlice.reducer;
