import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export type ModalType = "CUSTOMER_FORM";

export type ModalContent = {
  props?: any;
  view: ModalType;
};

type ModalState = {
  content?: ModalContent;
  isOpen: boolean;
};

const initialState: ModalState = {
  isOpen: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    closeModal: state => {
      return { ...state, isOpen: false} ;
    },
    openModal: (state, action) => {
      return { content: action.payload, isOpen: true };
    },
  },
});

export const {
  openModal,
  closeModal,
} = modalSlice.actions;

export default modalSlice.reducer;
