import Page from "containers/Page";
import Home from "containers/HomePage";
import TestimonialsPage from 'containers/TestimonialsPage';
import JobPage from 'containers/JobPage';
import TeamPage from 'containers/TeamPage';

type RouteNames = "HOME" | "PAGE" | "JOB_PAGE" | "TESTIMONIALS_PAGE" | "TEAM";

export type RouteObject = {
  path: string;
  component?: any;
  exact?: boolean;
  dev?: boolean;
};

const RouteMap: Record<RouteNames, RouteObject> = {
  HOME: {
    path: "/",
    component: Home,
    exact: true,
  },
  TEAM: {
    path: "/meet-the-team",
    component: TeamPage,
    exact: true,
  },
  TESTIMONIALS_PAGE: {
    path: "/testimonials",
    component: TestimonialsPage,
    exact: true,
  },
  JOB_PAGE: {
    path: "/careers/:slug",
    component: JobPage,
    exact: true,
  },
  PAGE: {
    path: "/:slug",
    component: Page,
    exact: true,
  },
};

export const ROUTES =
  process.env.NODE_ENV === "development"
    ? Object.values(RouteMap)
    : Object.values(RouteMap).filter(route => !route.dev);


export default RouteMap;
