import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { GlobalUI } from "cms";
import { FooterType } from 'components/Footer';
import { HeaderType } from 'components/Header';

type GlobalUIState = {
  headerUI?: HeaderType;
  footerUI?: FooterType;
};

export const fetchGlobalUI = createAsyncThunk<GlobalUIState>(
  "fetchGlobalUIStatus",
  async thunkAPI => {
    return await GlobalUI.fetch();
  }
);

const initialState: GlobalUIState = {};

const globalUISlice = createSlice({
  name: "globalUI",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchGlobalUI.fulfilled, (_, { payload }) => {
      return payload;
    });
  },
});

export default globalUISlice.reducer;
