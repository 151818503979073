import { connect } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { RouteComponentProps } from "react-router-dom";

import getParam from "utils/getParam";

import ContainerBase from "containers/ContainerBase";
import { PageProps } from "pages/Page";

import { fetchPage } from "state/slices/pages";
import { Dispatch } from "state/store";

import FiveHundredError from 'pages/FiveHundredError';
import Loading from "pages/Loading";

type Props = ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ slug: string, preview: string }>;

class Page extends ContainerBase<
  Props,
  PageProps,
  typeof import("pages/Page").default
  > {
  model = () => {
    const preview = getParam('generic_page_preview') || '';
    const slug = this.props.match.params.slug;
    return this.props.dispatch(fetchPage({ slug, preview })).then(unwrapResult);
  };
  view = import("pages/Page");
  errorView = FiveHundredError;
  loadingView = Loading;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
});

export default connect(mapDispatchToProps)(Page);
