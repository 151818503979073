import { connect } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { RouteComponentProps } from "react-router-dom";

import getParam from "utils/getParam";

import ContainerBase from "containers/ContainerBase";

import { fetchJobPage } from "state/slices/pages";
import { Dispatch } from "state/store";

import FiveHundredError from 'pages/FiveHundredError';
import { JobPageProps } from 'pages/JobPage';
import Loading from "pages/Loading";

type Props = ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ slug: string }>;

class JobPage extends ContainerBase<
  Props,
  JobPageProps,
  typeof import("pages/JobPage").default
  > {
  model = () => {
    const preview = getParam('job_page_preview') || '';
    const slug = this.props.match.params.slug;
    return this.props.dispatch(fetchJobPage({ slug, preview })).then(unwrapResult);
  };
  view = import("pages/JobPage");
  errorView = FiveHundredError;
  loadingView = Loading;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
});

export default connect(mapDispatchToProps)(JobPage);
