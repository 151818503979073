import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "state/reducers";

const store = configureStore({
  reducer: rootReducer,
});

export default store;

export type Dispatch = typeof store.dispatch;
