const image = `{
  'id': image.asset->_id,
  'src': image.asset->url,
  'lqip': image.asset->metadata.lqip,
  'aspectRatio': image.asset->metadata.dimensions.aspectRatio,
  'alt': image.alt,
}`;

const button = `{
  'id': _key,
  'link': button.link,
  'label': button.label,
  'variant': button.variant,
  'openCustomerForm': button.openCustomerForm,
}`;

const metadata = `{
  'title': metadata.title,
  'description': metadata.description,
  'image': {
    'id': metadata.image.asset->_id,
    'src': metadata.image.asset->url,
  },
}`;

const testimonials = `{
  'id': _id,
  'type': _type,
  videoId,
  name,
  salary,
  position,
  story,
}`;

const pageContent = `content[]{
  _key,
  _type,
  _type == 'essayBlock' => {
    title,
    bodyCopy,
  },
  _type == 'faq' => {
    title,
    'qAndAs': qAndAs[]{
      _key,
      question,
      answer,
    },
  },
  _type == 'fullWidthImage' => {
    'image': ${image},
    title,
    subtitle,
    'button': ${button},
    overlay,
  },
  _type == 'fullWidthVideo' => {
    videoId,
  },
  _type == 'headlineAndLink' => {
    title,
    'button': ${button},
    bgColor,
  },
  _type == 'iconList' => {
    title,
    'icons': icons[]{
      _key,
      'image': ${image},
      caption,
    },
  },
  _type == 'logoDisplay' => {
    title,
    'logos': logos[]{
      'id': asset->_id,
      'src': asset->url,
      'lqip': asset->metadata.lqip,
      'aspectRatio': asset->metadata.dimensions.aspectRatio,
      'alt': image.alt,
    },
  },
  _type == 'representativeTestimonials' => {
    title,
    'videos': videos[]->${testimonials},
  },
  _type == 'threeColumn' => {
    title,
    'columns': columns[]{
      _key,
      'image': ${image},
      caption,
    },
    bgColor,
    'button': ${button},
  },
}`;

export default {
  homePage: (preview: string) => `*[_type == 'homePage' ${
    preview !== "" ? `&& _id == '${preview}'` : ""
  }][0]{
    'id': _id,
    _type,
    'metadata': ${metadata},
    'heroImage': {
      '_type': 'fullWidthImage',
      'image': {
        'id': heroImage.image.asset->_id,
        'src': heroImage.image.asset->url,
        'lqip': heroImage.image.asset->metadata.lqip,
        'aspectRatio': heroImage.image.asset->metadata.dimensions.aspectRatio,
        'alt': heroImage.image.alt,
      },
      'title': heroImage.title,
      'subtitle': heroImage.subtitle,
      'button': {
        _id,
        'link': heroImage.button.link,
        'label': heroImage.button.label,
        'variant': heroImage.button.variant,
        'openCustomerForm': heroImage.button.openCustomerForm,
      },
      overlay,
    },
    'content': ${pageContent},
  }`,
  teamPage: (preview: string) => `*[_type == 'teamPage' ${
    preview !== "" ? `&& _id == '${preview}'` : ""
  }][0]{
    'id': _id,
    _type,
    'metadata': ${metadata},
    'pageHero': pageHero{
      text,
      'button': {
        _id,
        'link': button.link,
        'label': button.label,
        'variant': button.variant,
        'openCustomerForm': button.openCustomerForm,
      },
      bgColor,
      'image': {
        'id': image.asset->_id,
        'src': image.asset->url,
        'lqip': image.asset->metadata.lqip,
        'aspectRatio': image.asset->metadata.dimensions.aspectRatio,
        'alt': image.alt,
      },
      overlay,
    },
    'lead': lead{
      _id,
      name,
      position,
      bio,
      'image': ${image},
      'socialMedia': socialMedia[]{
        name,
        'logo': {
          'id': logo.asset->_id,
          'src': logo.asset->url,
          'lqip': logo.asset->metadata.lqip,
          'aspectRatio': logo.asset->metadata.dimensions.aspectRatio,
          'alt': logo.alt,
        },
        link,
      },
    },
    'teams': teams[]{
      title,
      'employees': employees[]{
        name,
        position,
        bio,
        'image': ${image},
        'socialMedia': socialMedia[]{
          name,
          'logo': {
            'id': logo.asset->_id,
            'src': logo.asset->url,
            'lqip': logo.asset->metadata.lqip,
            'aspectRatio': logo.asset->metadata.dimensions.aspectRatio,
            'alt': logo.alt,
          },
          link,
        },
      },
    },
  }`,
  page: (
    slug: string,
    preview: string
  ) => `*[_type == 'page' && slug == '${slug}' ${
    preview !== "" ? `&& _id == '${preview}'` : ""
  }][0]{
    _id,
    _type,
    title,
    slug,
    'metadata': ${metadata},
    'pageHero': pageHero{
      text,
      'button': {
        _id,
        'link': button.link,
        'label': button.label,
        'variant': button.variant,
        'openCustomerForm': button.openCustomerForm,
      },
      bgColor,
      'image': {
        'id': image.asset->_id,
        'src': image.asset->url,
        'lqip': image.asset->metadata.lqip,
        'aspectRatio': image.asset->metadata.dimensions.aspectRatio,
        'alt': image.alt,
      },
      overlay,
    },
    'content': ${pageContent},
  }`,
  jobPage: (
    slug: string,
    preview: string
  ) => `*[_type == 'jobPage' && slug == '${slug}' ${
    preview !== "" ? `&& _id == '${preview}'` : ""
  }][0]{
    _id,
    _type,
    'metadata': ${metadata},
    title,
    subtitle,
    tags,
    slug,
    content,
  }`,
  globalUI: `*[_type == 'globalUI'][0] {
    'id': _id,
    'headerUI': headerUI{
      'logo': {
        'id': logo.asset->_id,
        'src': logo.asset->url,
        'lqip': logo.asset->metadata.lqip,
        'aspectRatio': logo.asset->metadata.dimensions.aspectRatio,
        'alt': logo.alt,
      },
      'links': links[]->{_id, title, slug},
    },
    'footerUI': footerUI{
      'logo': {
        'id': logo.asset->_id,
        'src': logo.asset->url,
        'lqip': logo.asset->metadata.lqip,
        'aspectRatio': logo.asset->metadata.dimensions.aspectRatio,
        'alt': logo.alt,
      },
      'socialMedia': socialMedia[]{
        name,
        'logo': {
          'id': logo.asset->_id,
          'src': logo.asset->url,
          'lqip': logo.asset->metadata.lqip,
          'aspectRatio': logo.asset->metadata.dimensions.aspectRatio,
          'alt': logo.alt,
        },
        link,
      },
      'columnOne': columnOne[],
      'columnTwo': columnTwo[],
      'columnThree': columnThree[],
    }
  }`,
  testimonialVideos: `*[_type == 'testimonialVideos']`,
};
