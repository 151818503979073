import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FromInput } from 'components/JobForm';

const initialState: FromInput = {
  name: { id: "entry.681351255", value: "" },
  email: { id: "entry.191847954", value: "" },
  location: { id: "entry.1758002797", value: "Illinois" },
  phoneNumber: { id: "entry.1654034207", value: "" },
  dateAndTime: { 
    id: "entry.965492230",
    timeOne: "",
    timeTwo: "",
    timeThree: "",
    dateOne: "",
    dateTwo: "",
    dateThree: "",
    value: "",
  },
  license: { id: "entry.1995786041", value: "yes" },
  resume: { id: "entry.1463379058", value: "" },
};

const jobFormSlice = createSlice({
  name: "jobForm",
  initialState,
  reducers: {
    updateFormName: (state, action: PayloadAction<string>) => {
      state.name.value = action.payload;
    },
    updateFormEmail: (state, action: PayloadAction<string>) => {
      state.email.value = action.payload;
    },
    updateFormLocation: (state, action: PayloadAction<string>) => {
      state.location.value = action.payload;
    },
    updateFormPhone: (state, action: PayloadAction<string>) => {
      state.phoneNumber.value = action.payload;
    },
    updateFormTimeOne: (state, action: PayloadAction<string>) => {
      state.dateAndTime.timeOne = action.payload;
    },
    updateFormTimeTwo: (state, action: PayloadAction<string>) => {
      state.dateAndTime.timeTwo = action.payload;
    },
    updateFormTimeThree: (state, action: PayloadAction<string>) => {
      state.dateAndTime.timeThree = action.payload;
    },
    updateFormDateOne: (state, action: PayloadAction<string>) => {
      state.dateAndTime.dateOne = action.payload;
    },
    updateFormDateTwo: (state, action: PayloadAction<string>) => {
      state.dateAndTime.dateTwo = action.payload;
    },
    updateFormDateThree: (state, action: PayloadAction<string>) => {
      state.dateAndTime.dateThree = action.payload;
    },
    updateLicense: (state, action: PayloadAction<string>) => {
      state.license.value = action.payload;
    },
    updateFormResume: (state, action: PayloadAction<File | string>) => {
      state.resume.value = `${process.env.REACT_APP_S3_URL}${action.payload}`;
    },
    resetForm: (state) => {
      state = initialState;
    }
  },
});

export const {
  updateFormName,
  updateFormEmail,
  updateFormLocation,
  updateFormPhone,
  updateLicense,
  updateFormResume,
  updateFormDateOne,
  updateFormDateTwo,
  updateFormDateThree,
  updateFormTimeOne,
  updateFormTimeTwo,
  updateFormTimeThree,
  resetForm,
 } = jobFormSlice.actions;

export default jobFormSlice.reducer;