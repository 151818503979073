import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { initialize, Status } from "state/slices/app";
import { RootState } from "state/reducers";

import { ROUTES } from "pages/Routes";

// import FiveHundred from "pages/error/FiveHundred";
import Loading from "pages/Loading";

import ScrollToTop from "hooks/useScrollToTop";

import "styles/index.scss";

const { IDLE, REJECTED, PENDING } = Status;

function App() {
  const dispatch = useDispatch();
  const { status } = useSelector((state: RootState) => state.app);

  useEffect(() => {
    dispatch(initialize());
  }, [dispatch]);

  ScrollToTop();

  if (status.initialize === REJECTED) return <span> Error </span>;
  if ([IDLE, PENDING].includes(status.initialize)) return <Loading />;

  return (
    <>
      <Switch>
        {ROUTES.map(route => (
          <Route key={route.path} {...route} />
        ))}
      </Switch>
    </>
  );
}

export default App;
