import { connect } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { RouteComponentProps } from "react-router-dom";

import ContainerBase from "containers/ContainerBase";

import { fetchTestimonialsPage } from "state/slices/pages";
import { Dispatch } from "state/store";

import FiveHundredError from 'pages/FiveHundredError';
import { TestimonalType } from 'components/Testimonal';
import Loading from "pages/Loading";

type Props = ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}>;

class TestimonialsPage extends ContainerBase<
  Props,
  TestimonalType[],
  typeof import("pages/TestimonialsPage").default
  > {
  model = () => this.props.dispatch(fetchTestimonialsPage()).then(unwrapResult);
  view = import("pages/TestimonialsPage");
  errorView = FiveHundredError;
  loadingView = Loading;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
});

export default connect(mapDispatchToProps)(TestimonialsPage);
