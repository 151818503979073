import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { Dispatch } from "state/store";
import { RootState } from "state/reducers";
import { fetchGlobalUI } from './globalUI';

export enum Status {
  IDLE,
  PENDING,
  FULFILLED,
  REJECTED,
}

export const initialize = createAsyncThunk<
  any,
  undefined,
  { dispatch: Dispatch; state: RootState }
>("app/initialize", (_, { dispatch, getState }) => {
  const promises = [];

  promises.push(dispatch(fetchGlobalUI()).then(() => "ok"));

  return Promise.all(promises).then(() => "ok");
});

type AppState = {
  errors: {
    initialize?: true;
  };
  status: {
    initialize: Status;
  };
};

const initialState: AppState = {
  errors: {},
  status: {
    initialize: Status.IDLE,
  },
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(initialize.pending, state => {
      return {
        ...state,
        errors: {
          ...state.errors,
          initialize: undefined,
        },
        status: {
          ...state.status,
          initialize: Status.PENDING,
        },
      };
    });
    builder.addCase(initialize.fulfilled, state => {
      return {
        ...state,
        status: {
          ...state.status,
          initialize: Status.FULFILLED,
        },
      };
    });
    builder.addCase(initialize.rejected, state => ({
      ...state,
      errors: {
        ...state.errors,
        status: {
          ...state.status,
          initialize: Status.REJECTED,
        },
      },
    }));
  },
});

export default appSlice.reducer;
