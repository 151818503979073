const getParam = (target: string): string => {
  let result: string = '';
  
  window.location.search
    .substr(1)
    .split('&')
    .forEach((item) => {
      const [key, value] = item.split('=');
      if (key === target) result = decodeURIComponent(value);
    });

  return result;
};

export default getParam;