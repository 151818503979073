import { combineReducers } from 'redux';

import appReducer from "state/slices/app";
import jobFormReducer from "state/slices/jobForm";
import globalUIReducer from "state/slices/globalUI";
import modalReducer from "state/slices/modal";
import pagesReducer from "state/slices/pages";

const rootReducer = combineReducers({
  app: appReducer,
  pages: pagesReducer,
  globalUI: globalUIReducer,
  jobForm: jobFormReducer,
  modal: modalReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
