import { connect } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { RouteComponentProps } from "react-router-dom";

import { Dispatch } from "state/store";

import getParam from "utils/getParam";

import ContainerBase from "./ContainerBase";
import { HomePageProps } from "pages/HomePage";

import { fetchHomePage } from "state/slices/pages";

import FiveHundredError from "pages/FiveHundredError";
import Loading from "pages/Loading";

type Props = ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{preview: string}>;

class HomePage extends ContainerBase<
  Props,
  HomePageProps,
  typeof import("pages/HomePage").default
> {
  model = () => {
    const preview = getParam('home_page_preview') || '';
    return this.props.dispatch(fetchHomePage(preview)).then(unwrapResult);
  };
  view = import("pages/HomePage");
  errorView = FiveHundredError;
  loadingView = Loading;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
});

export default connect(mapDispatchToProps)(HomePage);
