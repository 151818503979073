import Sanity, { PreviewClient } from "./config";
import Queries from "./queries";

export const Pages = {
  fetchHomePage(preview: string) {
    const Client = preview ? PreviewClient : Sanity;
    return Client.fetch(Queries.homePage(preview))
      .then(response => response)
      .catch(err => console.log(err));
  },
  fetchPage(slug: string, preview: string) {
    const Client = preview ? PreviewClient : Sanity;
    return Client.fetch(Queries.page(slug, preview))
      .then(response => response)
      .catch(err => console.log(err));
  },
  fetchJobPage(slug: string, preview: string) {
    const Client = preview ? PreviewClient : Sanity;
    return Client.fetch(Queries.jobPage(slug, preview))
      .then(response => response)
      .catch(err => console.log(err));
  },
  fetchTeamPage(preview: string) {
    const Client = preview ? PreviewClient : Sanity;
    return Client.fetch(Queries.teamPage(preview))
      .then(response => response)
      .catch(err => console.log(err));
  },
  fetchTestimonialPage() {
    return Sanity.fetch(Queries.testimonialVideos)
      .then(response => response)
      .catch(err => console.log(err));
  },
};

export const GlobalUI = {
  fetch() {
    return Sanity.fetch(Queries.globalUI)
      .then(response => response)
      .catch(err => console.log(err));
  },
};
