import SanityClient from "@sanity/client";

export const SANITY_PROJECT_ID = "3nksa9v3";
export const SANITY_DATASET = "production";

const Sanity = SanityClient({
  projectId: SANITY_PROJECT_ID,
  dataset: SANITY_DATASET || "production",
  apiVersion: '2021-03-25',
  useCdn: false,
});

const SanityPreviewClient = SanityClient({
  projectId: SANITY_PROJECT_ID,
  dataset: SANITY_DATASET || "production",
  token: process.env.REACT_APP_SANITY_TOKEN,
  apiVersion: '2021-03-25',
  useCdn: false,
});

export const PreviewClient = SanityPreviewClient;
export default Sanity;
