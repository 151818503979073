import React, { FC } from "react";


const FiveHundredError: FC = () => {

  return (
    <div>
      There was an error
    </div>
  );
};

export default FiveHundredError;