import { connect } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { RouteComponentProps } from "react-router-dom";

import { Dispatch } from "state/store";

import getParam from "utils/getParam";

import ContainerBase from "./ContainerBase";
import { TeamPageProps } from "pages/TeamPage";

import { fetchTeamPage } from "state/slices/pages";

import FiveHundredError from "pages/FiveHundredError";
import Loading from "pages/Loading";

type Props = ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{ preview: string }>;

class TeamPage extends ContainerBase<
  Props,
  TeamPageProps,
  typeof import("pages/TeamPage").default
  > {
  model = () => {
    const preview = getParam('team_page_preview') || '';
    return this.props.dispatch(fetchTeamPage(preview)).then(unwrapResult);
  };
  view = import("pages/TeamPage");
  errorView = FiveHundredError;
  loadingView = Loading;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
});

export default connect(mapDispatchToProps)(TeamPage);
